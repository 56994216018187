








































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import ButtonComponent from '@/components/ButtonComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import VideoBackground from '@/components/wrappers/VideoBackground.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

/* eslint-disable no-nested-ternary */
@Component({
  components: {
    AvatarSoloWidget,
    ButtonComponent,
    Carousel3d,
    Slide,
    VideoBackground,
  },
})
export default class CarouselComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'CarouselWidgetStore';

  @Prop({ required: false, default: () => [] })
  private readonly slides!: Data[];

  private FileResourceHelper = FileResourceHelper;

  private width = 920;

  private height = 544;

  private space = 310;

  private avatarSize = '96';

  private buttonSize = 'lg';

  private get responsiveWidth(): number {
    return this.width;
  }

  private get responsiveHeight(): number {
    return this.height;
  }

  private get responsiveSpace(): number {
    return this.space;
  }

  private get videoAspectRatio(): string {
    if (this.isMobile) {
      return 'video-small-ratio';
    }
    if (this.isTablet) {
      return 'video-tablet-ratio';
    }
    return 'video-regular-ratio';
  }

  created(): void {
    this.setDataConfig();
  }

  mounted(): void {
    this.updatedValue();
  }

  private onMainSlideClick(payload: { index: number }): void {
    if (this.slides[payload.index]
      && this.slides[payload.index].route
      && (this.slides[payload.index].route as string).length > 0) {
      this.handleClick(this.slides[payload.index].route as string);
    }
  }

  private handleClick(route: string): void {
    if (route) {
      let external = route.startsWith('//');
      if (external) {
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code || '',
          '',
          StatLoggerActions.CLICK,
          route,
          -1,
          '',
          StatLoggerCategories.CAROUSEL,
          this.$i18n.locale,
        );
        window.open(route, '_blank');
      } else {
        try {
          const url = new URL(route);
          external = !!url.protocol;
          if (external) {
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code || '',
              '',
              StatLoggerActions.CLICK,
              route,
              -1,
              '',
              StatLoggerCategories.CAROUSEL,
              this.$i18n.locale,
            );
            window.open(route, '_blank');
          }
        } catch {
          let r = route;
          const matches = route.match(/(%[a-zA-Z-_]+%)/gs);
          if (matches) {
            matches.forEach((m) => {
              const prop = m.replaceAll('%', '').trim();
              if (this.$route.params[prop]) {
                r = r.replaceAll(m, this.$route.params[prop]);
              }
            });
          }
          const { name } = this.$router.resolve(r).route;
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code || '',
            '',
            StatLoggerActions.CLICK,
            name || r,
            -1,
            '',
            StatLoggerCategories.CAROUSEL,
            this.$i18n.locale,
          );
          this.$router.push(r);
        }
      }
    }
  }

  @Watch('slides')
  @Watch('breakpoint.value')
  private updatedValue(): void {
    if (this.slides.length > 1) {
      if (this.breakpoint.value === 'xs') {
        this.width = 768;
        this.height = 768;
        this.space = 0;
        this.avatarSize = '40';
        this.buttonSize = 'sm';
      }
      if (this.breakpoint.value === 'sm') {
        this.width = 510;
        this.height = 500;
        this.space = 0;
        this.avatarSize = '40';
        this.buttonSize = 'md';
      }
      if (this.breakpoint.value === 'md') {
        this.width = 500;
        this.height = 336;
        this.space = 230;
        this.avatarSize = '56';
        this.buttonSize = 'md';
      }
      if (this.breakpoint.value === 'lg') {
        this.width = 740;
        this.height = 500;
        this.space = 280;
        this.avatarSize = '56';
        this.buttonSize = 'md';
      }
      if (this.breakpoint.value === 'xl') {
        this.width = 920;
        this.height = 544;
        this.space = 310;
        this.avatarSize = '96';
        this.buttonSize = 'lg';
      }
    } else {
      this.width = 1140;
      this.height = 544;
      this.space = 310;
      this.avatarSize = this.isDesktop
        ? '96'
        : this.breakpoint.value === 'lg'
          ? '56'
          : this.breakpoint.value === 'md'
            ? '56'
            : '40';
      this.buttonSize = this.isDesktop
        ? 'lg'
        : this.breakpoint.value === 'lg'
          ? 'lg' : this.breakpoint.value === 'md'
            ? 'md'
            : 'sm';
    }
  }
}
