var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay && _vm.slides && _vm.slides.length > 0)?_c('div',{class:['container-fluid', _vm.classList]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"carousel-featured"},[_c('carousel3d',{attrs:{"border":0,"controls-height":_vm.isDesktop ? 56 : 48,"controls-visible":_vm.slides.length > 1 && !_vm.isMobile,"controls-width":_vm.isDesktop ? 56 : 48,"display":3,"height":_vm.responsiveHeight,"min-swipe-distance":150,"on-main-slide-click":_vm.onMainSlideClick,"perspective":0,"space":_vm.responsiveSpace,"width":_vm.responsiveWidth}},_vm._l((_vm.slides),function(slide,index){return _c('slide',{key:index,class:['slide rounded', slide.classes],style:(slide.thumbnail
                && !slide.thumbnail.path.includes('.mp4')
                && !slide.thumbnail.path.includes('.webm')
                ? { backgroundImage: ("url(" + (slide.thumbnail.path) + ")")}
                : ''),attrs:{"index":index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var isCurrent = ref.isCurrent;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column px-5\n                 w-100 h-100"},[(slide.logo)?_c('avatar-solo-widget',{staticClass:"space-b",attrs:{"size":_vm.avatarSize,"square":true,"src":_vm.FileResourceHelper.getImagePathWithSize(slide.logo.path, 'w96')}}):_vm._e(),(_vm.isDesktop)?[(slide.title)?_c('h1',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.$t(slide.title)),expression:"$t(slide.title)"}],staticClass:"text-white text-center m-0"}):_vm._e()]:[(slide.title)?_c('h3',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.$t(slide.title)),expression:"$t(slide.title)"}],staticClass:"text-white text-center m-0"}):_vm._e()],(slide.subtitle && !_vm.isMobile)?_c('p',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.$t(slide.subtitle)),expression:"$t(slide.subtitle)"}],staticClass:"headline text-center d-none d-md-flex text-white mt-2"}):_vm._e(),(slide.btnTitle && slide.route)?_c('button-component',{staticClass:"space-t w-fit",attrs:{"is-html":true,"size":_vm.buttonSize,"text":_vm.$t(slide.btnTitle),"variant":"tertiary"},on:{"on-click":function($event){return _vm.handleClick(slide.route)}}}):_vm._e(),(_vm.slides.length > 1)?_c('div',{staticClass:"navigation"},_vm._l((_vm.slides),function(_,i){return _c('span',{key:i,staticClass:"step",class:{ active: i === index }})}),0):_vm._e(),(slide.thumbnail
                      && slide.thumbnail.path
                      && (slide.thumbnail.path.includes('.mp4')
                        || slide.thumbnail.path.includes('.webm')))?_c('video-background',{class:[_vm.videoAspectRatio, 'h-auto position-absolute w-100 video-player-carousel'],attrs:{"is-current":isCurrent,"sources":[slide.thumbnail.path]}}):_vm._e()],2)]}}],null,true)})}),1)],1)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }